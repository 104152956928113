import { createRouter, createWebHistory } from 'vue-router';

const LoginPage = () => import('./views/LoginPage.vue');
const ParentOverview = () => import('./views/ParentOverview.vue');
const ChildOverview = () => import('./views/ChildOverview.vue');
const AdminSettings = () => import('./views/AdminSettings.vue');
const AdminOverview = () => import('./views/AdminOverview.vue');
const AdminMessages = () => import('./views/AdminMessages.vue');
const AdminAddEditActivity = () => import('./views/AdminAddEditActivity.vue');
const AdminUserOverview = () => import('./views/AdminUserOverview.vue');
const AdminLocationOverview = () => import('./views/AdminLocationOverview.vue');
const AdminLocationsDetail = () => import('./views/AdminLocationsDetail.vue');
const AdminCohortOverview = () => import('./views/AdminCohortOverview.vue');
const AdminCalendar = () => import('./views/AdminCalendar.vue');
const AdminUserDetail = () => import('./views/AdminUserDetail.vue');
const AdminCohortDetail = () => import('./views/AdminCohortDetail.vue');
const ParentBookedOverview = () => import('./views/ParentBookedOverview.vue');
const ParentsCalendar = () => import('./views/ParentCalendar.vue');
const ParentsHome = () => import('./views/ParentHome.vue');
const ParentSettings = () => import('./views/ParentSettings.vue');
const ParentCohortDetail = () => import('./views/ParentCohortDetail.vue');
const ParentsMessages = () => import('./views/ParentMessages.vue');
const ParentActivityDetail = () => import('./views/ParentActivityDetail.vue');
const ParentReminders = () => import('./views/ParentReminders.vue');
const TeacherOverview = () => import('./views/TeacherOverview.vue');
const TeacherActivityDetail = () => import('./views/TeacherActivityDetail.vue');
const TeacherCalendar = () => import('./views/TeacherCalendar.vue');
const TeacherSettings = () => import('./views/TeacherSettings.vue');
const TeacherCohortDetail = () => import('./views/TeacherCohortDetail.vue');
const TeacherAddEditActivity = () => import('./views/TeacherAddEditActivity.vue');
const ActivityDetail = () => import('./views/AdminActivityDetail.vue');
const ParentBookedActivityDetail = () => import('./views/ParentBookedActivityDetail.vue');
const AdminActivityApproval = () => import('./views/AdminActivityApproval.vue');
const AdminHolidays = () => import('./views/AdminHolidays.vue');
const AdminPermissionManagement = () => import('./views/AdminPermissionManagement.vue');
const AdminTasks = () => import('./views/AdminTasks.vue');
const AdminPermissionDetail = () => import('./views/AdminPermissionDetail.vue');

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'LoginPage', component: LoginPage },
  { path: '/child-overview', name: 'ChildOverview', component: ChildOverview },

  // Admin routes
  { path: '/admin-overview', name: 'AdminOverview', component: AdminOverview, meta: { requiresAdmin: true } },
  { path: '/admin-calendar', name: 'AdminCalendar', component: AdminCalendar, meta: { requiresAdmin: true } },
  { path: '/admin-settings', name: 'AdminSettings', component: AdminSettings, meta: { requiresAdmin: true } },
  { path: '/admin-user-overview', name: 'AdminUserOverview', component: AdminUserOverview, meta: { requiresAdmin: true } },
  { path: '/admin-location-overview', name: 'AdminLocationOverview', component: AdminLocationOverview, meta: { requiresAdmin: true } },
  { path: '/admin-locations-detail/:locationId', name: 'AdminLocationsDetail', component: AdminLocationsDetail, meta: { requiresAdmin: true } },
  { path: '/admin-cohort-overview', name: 'AdminCohortOverview', component: AdminCohortOverview, meta: { requiresAdmin: true } },
  { path: '/admin-add-edit-activity/:activityId?', name: 'AdminAddEditActivity', component: AdminAddEditActivity, meta: { requiresAdmin: true } },
  { path: '/admin-cohort/:cohortId', name: 'AdminCohortDetail', component: AdminCohortDetail, meta: { requiresAdmin: true } },
  { path: '/admin/user/:userId', name: 'AdminUserDetail', component: AdminUserDetail, meta: { requiresAdmin: true } },
  { path: '/admin-activity-approval', name: 'AdminActivityApproval', component: AdminActivityApproval, meta: { requiresAdmin: true } },
  { path: '/admin-messages', name: 'AdminMessages', component: AdminMessages, meta: { requiresAdmin: true } },
  { path: '/admin-holidays', name: 'AdminHolidays', component: AdminHolidays, meta: { requiresAdmin: true } },
  { path: '/admin-permission-management', name: 'AdminPermissionManagement', component: AdminPermissionManagement, meta: { requiresAdmin: true } },
  { path: '/admin-tasks', name: 'AdminTasks', component: AdminTasks, meta: { requiresAdmin: true } },
  { path: '/admin/role/:roleId', name: 'AdminPermissionDetail', component: AdminPermissionDetail, meta: { requiresAdmin: true } },

  // Parent routes
  { path: '/parent-overview', name: 'ParentOverview', component: ParentOverview, meta: { requiresParent: true } },
  { path: '/parent-booked-overview', name: 'ParentBookedOverview', component: ParentBookedOverview, meta: { requiresParent: true } },
  { path: '/parents-calendar', name: 'ParentsCalendar', component: ParentsCalendar, meta: { requiresParent: true } },
  { path: '/parent-settings', name: 'ParentSettings', component: ParentSettings, meta: { requiresParent: true } },
  { path: '/parent-activity/:activityId', name: 'ParentActivityDetail', component: ParentActivityDetail, meta: { requiresParent: true } },
  { path: '/parents-messages', name: 'ParentsMessages', component: ParentsMessages, meta: { requiresParent: true } },
  { path: '/parents-home', name: 'ParentsHome', component: ParentsHome, meta: { requiresParent: true } },
  { path: '/parent-cohort/:cohortId', name: 'ParentCohortDetail', component: ParentCohortDetail, meta: { requiresParent: true } },
  { path: '/parent-reminders', name: 'ParentReminders', component: ParentReminders, meta: { requiresParent: true } },

  // Teacher routes
  { path: '/teacher-overview', name: 'TeacherOverview', component: TeacherOverview, meta: { requiresTeacher: true } },
  { path: '/teacher-activity/:activityId', name: 'TeacherActivityDetail', component: TeacherActivityDetail, meta: { requiresTeacher: true } },
  { path: '/teacher-calendar', name: 'TeacherCalendar', component: TeacherCalendar, meta: { requiresTeacher: true } },
  { path: '/teacher-settings', name: 'TeacherSettings', component: TeacherSettings, meta: { requiresTeacher: true } },
  { path: '/teacher-cohort/:cohortId', name: 'TeacherCohortDetail', component: TeacherCohortDetail, meta: { requiresTeacher: true } },
  { path: '/teacher-cohort/:cohortId/activity/add', name: 'TeacherAddEditActivity', component: TeacherAddEditActivity, meta: { requiresTeacher: true } },

  // Common routes
  { path: '/activity/:activityId', name: 'ActivityDetail', component: ActivityDetail },
  { path: '/activities/:activityId', name: 'ParentBookedActivityDetail', component: ParentBookedActivityDetail, props: true, meta: { requiresParent: true, requiresChild: true } }
];

// Create router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation Guard
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user-token');
  const roles = JSON.parse(localStorage.getItem('user-roles') || '[]');

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (loggedIn && roles.includes('admin')) {
      next();
    } else {
      next('/login');
    }
  } else if (to.matched.some(record => record.meta.requiresParent)) {
    if (loggedIn && roles.includes('parent')) {
      next();
    } else {
      next('/login');
    }
  } else if (to.matched.some(record => record.meta.requiresTeacher)) {
    if (loggedIn && roles.includes('teacher')) {
      next();
    } else {
      next('/login');
    }
  } else if (to.matched.some(record => record.meta.requiresChild)) {
    if (loggedIn && roles.includes('child')) {
      next();
    } else {
      next('/login');
    }
  } else {
    if (!loggedIn && to.path !== '/login') {
      next('/login');
    } else {
      next();
    }
  }
});

export default router;
