export default {
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrodiet pareizo aktivitāti"])},
  "bookActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervējiet no plaša aktivitāšu klāsta."])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laipni lūdzam jūsu rezervēšanas portālā"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotājvārds vai E-pasts"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierakstīties"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepareizs lietotājvārds vai parole"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidot administratoru"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotājvārds"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pasts"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidot"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iestatījumi"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties valodu"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainīt paroli"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējā parole"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlasīt visus bērnus"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunā parole"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es Apņemos"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nākamais"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija apstiprināta"])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aktivitāte '", _interpolate(_named("activity")), "' ir veiksmīgi rezervēta."])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šis laiks jau ir rezervēts. Lūdzu, izvēlieties citu pieejamo laiku."])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumi"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apņemšanās apstiprinājums"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' aktivitātes izmaksas tiks pievienotas jūsu ikmēneša rēķinam nākamos 3 mēnešus."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' aktivitātes izmaksas tiks pievienotas jūsu ikgadējam rēķinam."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' aktivitātes izmaksas tiks sadalītas ceturkšņa maksājumos."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' aktivitātes izmaksas tiks sadalītas divreiz gadā."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' pilna aktivitātes maksa tiks iekasēta uzreiz."])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumu pārvaldība"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties laika intervālus"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieejams"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājums"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstipriniet jauno paroli"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunināt paroli"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunās paroles nesakrīt."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole veiksmīgi atjaunināta."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroles atjaunināšana neizdevās."])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav pieejamu laika intervālu"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot lietotāju"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt melnrakstu"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt izmaiņas"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicēt lietotāju"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šo paroli lietotājs izmantos, lai pieteiktos. Pārliecinieties, ka tā ir droša. Administratori var iestatīt un mainīt paroles, bet lietotāji tās var mainīt pēc pieteikšanās. Administratori nevar skatīt lietotāju paroles."])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāja loma"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājumu informācija"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt maksājumu informāciju"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunināt maksājumu informāciju"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angļu"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vācu"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latviešu"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krievu"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoda"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lomu pārvaldība"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visas aktivitātes"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāju pārvaldība"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrašanās vietas pārvaldība"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortas pārvaldība"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendāra skats"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas jauns"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ārpusstundu aktivitātes"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāji"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigācija"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumi"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt aktivitātes"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties atrašanās vietu"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties skolotāju"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties kohortu"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piemērot"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iet uz detaļām"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mēneša skats"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedēļas skats"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienas skats"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervēšanas apstiprināšanas centrs"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brīvdienu pārvaldība"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašlaik nav nevienas neapstiprinātas rezervācijas."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt rezervāciju"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai vēlaties rezervēt ", _interpolate(_named("activityName")), " priekš ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt rezervāciju"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai vēlaties rezervēt"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priekš"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mēs sazināsimies ar jums par maksājuma detaļām. Aktivitātes cena ir ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), " (", _interpolate(_named("pricingType")), "). Pēc maksājuma veikšanas mēs varēsim apstiprināt rezervāciju."])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cena par sesiju ir ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Maksājums tiks pievienots jūsu ikmēneša rēķinam."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mēnesī"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atkārtota rezervācija"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai vēlaties rezervēt šo laiku katru nedēļu?"])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs noklikšķināsiet uz 'Es apņemos', sesijas izmaksas tiks pievienotas jūsu mēneša rēķinam."])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jā"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nē"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gadā"])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reizi ceturksnī"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divreiz gadā"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kopā"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējās aktivitātes"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidāmās aktivitātes"])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt pēc bērna"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaut kas nogāja greizi. Lūdzu, mēģiniet vēlreiz vēlāk."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstrāde..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitāte veiksmīgi rezervēta, gaidot apstiprinājumu."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai vēlaties atcelt rezervāciju par ", _interpolate(_named("activityName")), " priekš ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt atcelšanu"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt darbību"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai esat pārliecināts, ka vēlaties turpināt ar šo darbību?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija tika atcelta."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosaukums"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākuma datums"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigu datums"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laika sloti"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalībnieki"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortas"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darbības"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rindas vienā lapā"])}
}