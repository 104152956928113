// src/App.vue
<template>
  <div id="app">
    <router-view/>
    <UnauthorizedError :visible="unauthorizedErrorVisible" @update:visible="unauthorizedErrorVisible = $event" />
  </div>
</template>

<script>
import UnauthorizedError from '@/components/UnauthorizedError.vue'; 
import eventBus from './eventBus';

export default {
  components: {
    UnauthorizedError
  },
  data() {
    return {
      unauthorizedErrorVisible: false
    };
  },
  mounted() {
    eventBus.on('showUnauthorizedError', () => {
      this.unauthorizedErrorVisible = true;
    });
  }
};
</script>
